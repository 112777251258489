import {
    HdoDen,
    HdoSazbyInfo,
    Maybe,
    OdberneMistoElektrinaSmartMereniArgs,
    OdberneMistoElektrinaSmartMereniHodnota,
    SmlouvaOdbernehoMistaStav,
    SmlouvaOdbernehoMistaTypMereni,
    SmlouvyOdbernychMistFilter,
} from '@eon.cz/apollo13-graphql';
import {FiberManualRecordRounded} from '@mui/icons-material';
import {addDays, addMonths, closestIndexTo, differenceInDays, eachMinuteOfInterval, format, getDate, getMonth, isValid} from 'date-fns';
import {Options, SeriesOptionsType} from 'highcharts';
import {Dictionary, isEqual} from 'lodash';
import find from 'lodash/find';
import {Fragment} from 'react';
import {toApiDate} from '../../Utils/DateOperations';
import {sumFloatsWithForcedPrecision} from '../../Utils/NumberOperations';
import {OdbernaMistaFormFilterModel} from '../model';

const PREFIX_FORM_NAME = 'ODBERNA_MISTA';
const YMD_FORMAT = 'yyyy-MM-dd';
export const ODBERNA_MISTA_VSE = 'vse';
export const SOUCTOVY_PROFIL_ID = 'INPROF_E';
export const SMART_MERENI_SPOTREBY_PRECISION = 3;

export const graphColor = {
    AKU: '#795548',
    EMO: '#8A8A8E',
    TAR: '#1200E0',
    PV: '#78e0ed',
    PVTC: '#15C851',
    TUV: '#ff9800',
} as Record<string, string>;

export const OdberneMistoPlynKapacitaTyp = {
    KLOUZAVA: 'KLOUZAVA',
    MESICNI: 'MESICNI',
    NEURCITA: 'NEURCITA',
} as const;

export const OdberneMistoHDOGraf = {
    VERSACOM: 'VERSACOM',
    VYCHOD: 'VYCHOD',
    ZAPAD: 'ZAPAD',
    TOU: 'TOU',
    SM: 'SM',
    PH: 'PH',
} as Record<string, string>;

export type OdberneMistoHDOGraf = (typeof OdberneMistoHDOGraf)[keyof typeof OdberneMistoHDOGraf];

export type OdberneMistoPlynKapacitaTyp = (typeof OdberneMistoPlynKapacitaTyp)[keyof typeof OdberneMistoPlynKapacitaTyp];
export const OdbernaMistaService = {
    FILTER_FORM_NAME: `${PREFIX_FORM_NAME}_FILTER_FORM_NAME`,

    getStavyOM(): Array<SmlouvaOdbernehoMistaStav | 'vse'> {
        return [ODBERNA_MISTA_VSE, ...Object.values(SmlouvaOdbernehoMistaStav)];
    },

    getTypMereniOM(): Array<SmlouvaOdbernehoMistaTypMereni | 'vse'> {
        return [ODBERNA_MISTA_VSE, ...Object.values(SmlouvaOdbernehoMistaTypMereni)];
    },

    // mapování hodnot z filtrace na GraphQL volání pro OM
    remapFormModelToGraphQLFilter(filter: OdbernaMistaFormFilterModel): SmlouvyOdbernychMistFilter {
        return {
            fulltextEicEan: !!filter.eicEanSpotrebni || filter.eicEanSpotrebni !== '' ? filter.eicEanSpotrebni : undefined,
            fulltextAdresa: !!filter.adresa || filter.adresa !== '' ? filter.adresa : undefined,
            fulltextZakaznik: !!filter.zakaznik || filter.zakaznik != '' ? filter.zakaznik : undefined,
            datumOd: !!filter.datumOd && filter.datumOd !== '' ? filter.datumOd : undefined,
            datumDo: !!filter.datumDo && filter.datumDo !== '' ? filter.datumDo : undefined,
            stav: filter.stav === ODBERNA_MISTA_VSE ? undefined : filter.stav,
            typMereni: filter.typMereni === ODBERNA_MISTA_VSE ? undefined : filter.typMereni,
            fulltextNazev: filter.nazev ?? null,
        };
    },

    // mapování hodnot na model pro filtraci historie spotřeb
    mapSmartMereniGraphQLFilterToFormModel({idPristroje, typDiagramu, datumOd, datumDo}: OdberneMistoElektrinaSmartMereniArgs) {
        return {
            idPristroje: idPristroje ?? '',
            typDiagramu,
            datumOd: toApiDate(datumOd),
            datumDo: toApiDate(datumDo),
        };
    },

    /**
     * Vrátí unikátní id přístroje složené z čísla přístroje a vygenerovaného ID
     *
     * @param {string} idPristroje
     * @param {string} id
     */
    getFullIdPristroje(idPristroje: string | undefined, id: string | undefined) {
        return `${idPristroje}:${id}`;
    },

    /**
     * Odebere ze stringu generované id a vrátí jen idPristroje (${cislo}-${material})
     *
     * @param {string} fullIdPristroje
     */
    getCorrectedIdPristroje(fullIdPristroje: string) {
        const [idPristroje] = fullIdPristroje.split(':');
        return idPristroje;
    },

    // Funkce vrací podle typu měření textaci pro název tabu
    getLabelHistorieTypMereni(typ: 'A' | 'B' | 'C' | 'C123BNN', vyrobna?: boolean): string {
        switch (typ) {
            case 'A':
            case 'B':
            case 'C123BNN':
                if (vyrobna) {
                    return 'Historie spotřeb / dodávek';
                } else {
                    return 'Historie spotřeb';
                }

            case 'C':
                return 'Historie odečtů';
            default:
                return 'Neznámé';
        }
    },

    /**
     * Provede součet hodnot pro NT a VT.
     * Pokud NT není, vrací jen hodnotu VT.
     *
     * @param {OdberneMistoElektrinaSmartMereniHodnota} h - objekt obsahující hodnoty ve vysokém a nízkém tarifu (kWh)
     * @param {boolean} isSpotreba - kontroluje zda se má počítat s desetinnými čísly (float)
     */
    smartMereniMakeSoucet: (h: OdberneMistoElektrinaSmartMereniHodnota | null, isSpotreba: boolean) => {
        if (isSpotreba) {
            // u spotřeby sčítáme VT a NT, což jsou desetinná čísla se třemi desetinnými místy
            return !!h?.spotrebaNt ? sumFloatsWithForcedPrecision(h?.spotrebaNt, h?.spotrebaVt, SMART_MERENI_SPOTREBY_PRECISION) : h?.spotrebaVt;
        }
        // pro stav registru jsou hodnoty VT i NT v celých číslech
        return !!h?.spotrebaNt ? h?.spotrebaNt + h?.spotrebaVt : h?.spotrebaVt;
    },

    // Funkce pro možnost resetovat filtr
    isFilterActive: <T extends Record<any, any>>(filter: T, initialFilter: T) => {
        return !isEqual(filter, initialFilter);
    },

    /**
     * Spočítá nové koncové datum intervalu po změně počátečního data intervalu. Pokud byl původní intervalo celý měsíc a nový
     * začátek intervalu je prvním dnem měsíce, tak je opět intervalem celý měsíc (nové koncové datum je posledním dnem měsíce, ve kterém je první den intervalu).
     * Jinak se posune koncové datum tak, aby délka intervalu ve dnech zůstala stejná.
     *
     * @param oldDatumOdS Staré počáteční datum intervalu
     * @param newDatumOdS Nové počáteční datum intervalu
     * @param oldDatumDoS Staré koncové datum intervalu
     */
    computeNewDatumDo: (oldDatumOdS: string | null | undefined, newDatumOdS: string, oldDatumDoS: string | null) => {
        const isValidNewDatum = newDatumOdS !== null && isValid(new Date(newDatumOdS));
        const isValidOldDatumDoS = oldDatumDoS !== null && isValid(new Date(oldDatumDoS));
        // Parse dates
        if (!isValidNewDatum || !isValidOldDatumDoS) {
            return null;
        } else {
            const oldDatumOd = format(new Date(oldDatumOdS ?? '1970-01-01'), YMD_FORMAT);
            const newDatumOd = format(new Date(newDatumOdS), YMD_FORMAT);
            const oldDatumDo = format(new Date(oldDatumDoS), YMD_FORMAT);

            const oldDayFrom = getDate(new Date(oldDatumOd));
            const newDayFrom = getDate(new Date(newDatumOd));
            const oldMonthTo = getMonth(new Date(oldDatumDo)) + 1;
            const oldMonthToPlusOne = getMonth(addDays(new Date(oldDatumDo), 1)) + 1;

            if (oldDayFrom === 1 && oldMonthTo !== oldMonthToPlusOne && newDayFrom === 1) {
                // Moved from month to a month - return end of month
                return format(addMonths(addDays(new Date(newDatumOd), -1), 1), YMD_FORMAT);
            }

            // Compute difference in days
            const diffDays = differenceInDays(new Date(oldDatumDo), new Date(oldDatumOd));

            // Return new date from plus given number of days
            return format(addDays(new Date(newDatumOd), diffDays - 1), YMD_FORMAT);
        }
    },
    parser: (casOd: string | undefined, casDo: string | undefined) => {
        const datum = new Date();
        const interval = eachMinuteOfInterval({
            start: new Date(datum.setHours(Number(casOd?.split(':')[0]), Number(casOd?.split(':')[1]))),
            end: new Date(datum.setHours(Number(casDo?.split(':')[0]), Number(casDo?.split(':')[1]))),
        });
        return interval.map((item) => item.getTime());
    },

    getNazevSazby: (nazev: string | undefined) => {
        const kod = nazev?.split('-')[2];
        const moreThanOne = kod && kod?.split(',')?.length > 1;
        if (!kod)
            return (
                <span style={{display: 'flex'}}>
                    <FiberManualRecordRounded style={{color: '#1200E0'}} />
                    {nazev}
                </span>
            );

        const textace = {
            AKU: 'akumulační vytápění (AKU)',
            EMO: 'dobíjení auta (EMO)',
            PV: 'přímotopné vytápění (PV)',
            PVTC: 'vytápění čerpadlem (PVTC)',
            TAR: 'nízký tarif elektřiny (TAR)',
            TUV: 'ohřev teplé užitkové vody (TUV)',
        } as Record<string, string>;

        if (moreThanOne) {
            return (
                <span style={{display: 'flex'}}>
                    <FiberManualRecordRounded style={{color: graphColor[kod]}} />
                    {kod.split(',').map((item, index) => {
                        return (
                            <Fragment key={item}>
                                {textace[item]} {index === kod.split(',').length - 1 ? '' : ', '}
                            </Fragment>
                        );
                    })}
                </span>
            );
        }
        return (
            <span style={{display: 'flex'}}>
                <FiberManualRecordRounded style={{color: graphColor[kod]}} />
                {textace[kod]}
            </span>
        );
    },
    getNazevSazbyString: (nazev: string | undefined) => {
        const kod = nazev?.split('-')[2];
        const moreThanOne = kod && kod?.split(',')?.length > 1;
        if (!kod) return 'TAR';

        if (moreThanOne) {
            return kod.split(',').reduce((_, item, index) => `${item} ${index === kod.split(',').length - 1 ? '' : ','}`, '');
        }
        return kod;
    },
    getSazba: (nazev: string | undefined) => {
        const rele = nazev?.split('-')[1];
        const sazba = nazev?.split('-')[0];
        if (!rele) return '';

        if (sazba && sazba?.split(',').length > 1) return `${rele} - (${sazba?.split(',').map((item) => item)})`;
        return `${rele} - (${sazba})`;
    },

    predicate: (data: Maybe<HdoDen>[] | undefined) => {
        const predicate = (days: Array<number>) => (o: HdoDen) => days.includes(o.denVTydnu);
        const weekend = find(data, predicate([6, 7])) as HdoDen;
        const bussinesDay = find(data, predicate([1, 2, 3, 4, 5])) as HdoDen;
        return [bussinesDay, weekend];
    },

    getGroupedData: (data: Maybe<HdoSazbyInfo>[], isWeekend: boolean) => {
        const parser = OdbernaMistaService.parser('00:00', '24:00');
        const reduceParserOrigin = (dny: Maybe<HdoDen>[] | undefined, bDay: boolean) => {
            const [bussinesDay, weekend] = OdbernaMistaService.predicate(dny);
            const data = bDay ? bussinesDay : weekend;
            return data?.casy?.reduce((acc, cas) => {
                const parserOrigin = OdbernaMistaService.parser(cas?.od, cas?.do);
                return [...acc, ...parserOrigin];
            }, [] as number[]);
        };
        return data?.map((dat) => {
            return dat?.sazby?.map((item) => {
                if (!isWeekend) {
                    const parserOrigin = reduceParserOrigin(item?.dny, true);
                    const data = parser.map((item) => [item, parserOrigin?.some((itemOrigin) => itemOrigin === item) ? 1 : 0]);
                    return {
                        name: item?.sazba,
                        type: 'column',
                        data,
                        color: graphColor[item?.sazba?.split('-')[2]?.split(',')[0] as string],
                    };
                }
                if (isWeekend) {
                    const parserOrigin = reduceParserOrigin(item?.dny, false);
                    const data = parser.map((item) => [item, parserOrigin?.some((itemOrigin) => itemOrigin === item) ? 1 : 0]);

                    return {
                        name: item?.sazba,
                        type: 'column',
                        data,
                        color: graphColor[item?.sazba?.split('-')[2]?.split(',')[0] as string],
                    };
                }
            }, [] as SeriesOptionsType[]);
        });
    },
    getGroupedDataChata: (data: Maybe<HdoSazbyInfo>[], typGrafuChata: Partial<Record<'PATEK' | 'SOBOTA' | 'NEDELE', boolean>>) => {
        const parser = OdbernaMistaService.parser('00:00', '24:00');
        const reduceParserOrigin = (dny: Maybe<HdoDen>[] | undefined, whichDay: number) =>
            dny?.[whichDay]?.casy.reduce((acc, cas) => {
                const parserOrigin = OdbernaMistaService.parser(cas?.od, cas?.do);
                return [...acc, ...parserOrigin];
            }, [] as number[]);
        return data?.map((dat) => {
            return dat?.sazby?.map((item) => {
                if (typGrafuChata.PATEK) {
                    const parserOrigin = reduceParserOrigin(item?.dny, 0);
                    const data = parser.map((item) => [item, parserOrigin?.some((itemOrigin) => itemOrigin === item) ? 1 : 0]);
                    return {
                        name: item?.sazba,
                        type: 'column',
                        data,
                        color: graphColor[item?.sazba?.split('-')[2]?.split(',')[0] as string],
                    };
                }
                if (typGrafuChata.SOBOTA) {
                    const parserOrigin = reduceParserOrigin(item?.dny, 1);
                    const data = parser.map((item) => [item, parserOrigin?.some((itemOrigin) => itemOrigin === item) ? 1 : 0]);

                    return {
                        name: item?.sazba,
                        type: 'column',
                        data,
                        color: graphColor[item?.sazba?.split('-')[2]?.split(',')[0] as string],
                    };
                }
                if (typGrafuChata.NEDELE) {
                    const parserOrigin = reduceParserOrigin(item?.dny, 2);
                    const data = parser.map((item) => [item, parserOrigin?.some((itemOrigin) => itemOrigin === item) ? 1 : 0]);

                    return {
                        name: item?.sazba,
                        type: 'column',
                        data,
                        color: graphColor[item?.sazba?.split('-')[2]?.split(',')[0] as string],
                    };
                }
            }, [] as SeriesOptionsType[]);
        });
    },
    getAllSeries: (
        grouped: Dictionary<Maybe<HdoSazbyInfo>[]>,
        groupedNeaktivni: Dictionary<Maybe<HdoSazbyInfo>[]>,
        activeTabSelected: number,
        typGrafu: Record<OdberneMistoHDOGraf, boolean>,
        typGrafuChata: Partial<Record<'PATEK' | 'SOBOTA' | 'NEDELE', boolean>>,
    ) => {
        return Object.entries(activeTabSelected === 0 ? grouped : groupedNeaktivni).map(([key, value]) => {
            return {
                name: OdberneMistoHDOGraf[key] as OdberneMistoHDOGraf,
                group: value,
                canFiltering: value?.length !== 1,
                serie: OdbernaMistaService.getGroupedData(value, typGrafu[key]),
                serieChata: OdbernaMistaService.getGroupedDataChata(value, typGrafuChata),
            };
        });
    },

    extractObject: <T extends Array<any>>(data: T | undefined, isGas?: boolean): T[number] => {
        const key = isGas ? 'datumOd' : 'platnostOd';
        const filteredDates = data?.filter((item) => new Date(item[key]) <= new Date()) ?? [];
        const closesIndexDate = closestIndexTo(
            new Date(),
            filteredDates?.map((item) => new Date(item[key])),
        );
        return filteredDates?.[closesIndexDate as number];
    },
};

/**
 * Konfigurace grafu pro historii odečtů OM
 */
export const graphHDOconfig: Options = {
    chart: {
        type: 'column',
        backgroundColor: undefined,
        height: 200,
        zooming: {
            type: 'x',
        },
    },
    credits: {enabled: false},

    tooltip: {
        enabled: true,
        formatter() {
            return format(new Date(this.x as string), 'dd.MM.yyyy HH:mm');
        },
    },
    xAxis: {
        currentDateIndicator: {
            label: {
                format: '%A, %d.%m.%Y- %H:%M',
            },
        },
        type: 'datetime',
        crosshair: true,
    },
    yAxis: {
        title: {
            text: '',
        },
        labels: {
            enabled: false,
        },
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0,
        },
    },
    legend: {
        enabled: true,
    },
    time: {
        useUTC: false,
        timezone: 'Europe/Prague',
    },
};
